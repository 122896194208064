import React from "react";
import "../App.css";
import { Header, Footer } from "../component/index";
import { Helmet } from "react-helmet";

const Thanks = () => {
  const title = "Thanks | ROCK GYM セミパーソナルジム | 姫路";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="container">
        <Header />
        <p className="thanks-content">無料体験のお申し込み、ありがとうございます。<br/>入力いただいた申込確認方法にてご連絡致しますので、<br/>しばらくお待ちください。</p>
        <Footer />
      </div>
    </>
  );
}

export default Thanks;