import React from "react";
import "../App.css";
import { Header, Footer } from "../component/index";
import { Helmet } from "react-helmet";
import Interior from "../images/exterior/rockgym_extrior.jpg";
import GYM1 from "../images/gym_interior/gym_interior_1.jpg";
import GYM2 from "../images/gym_interior/gym_interior_2.jpg";

const StoreInfo = () => {
  const title = "StoreInfo | ROCK GYM セミパーソナルジム | 姫路";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="container">
        <div className="storeinfo-main">
          <Header />
          <div className="interior-image">
            <img src={Interior} alt="内装" />
          </div>
          <div className="store-info">
            <h2>店舗情報</h2>
            <div className="store-info-item">
              <p>住所</p>
              <p className="store-info-item-dif">〒672-8023<br/>兵庫県姫路市白浜町甲400-14 天マ屋ビル3階</p>
            </div>
            <div className="store-info-item">
              <p>電話番号</p>
              <p>080-6914-6969</p>
            </div>
            <div className="store-info-item">
              <p>メールアドレス</p>
              <p>rockgym6969@gmail.com</p>
            </div>
            <div className="store-info-item">
              <p>最寄駅</p>
              <p>山陽電車 白浜の宮駅から徒歩3分</p>
            </div>
            <div className="store-info-item">
              <p>駐車場</p>
              <p>有</p>
            </div>
            <div className="store-info-item">
              <p>営業時間</p>
              <p className="store-info-item-dif">8:30-21:30<br/>（曜日によって異なります）</p>
            </div>
            <div className="store-info-item">
              <p>定休日</p>
              <p>日曜日・祝日</p>
            </div>
          </div>
          <div className="gym-description">
            <h2>トレーニングルーム紹介</h2>
            <div className="gym-items">
              <div className="gym-item-subarea">
                <p><span>錘の間</span><br/>ダンベルやバーベルを使ったフリーウエイトトレーニングをメインに行います</p>
              </div>
              <img src={GYM1} class="gym-interior" alt="img..."/>
            </div>
            <div className="gym-items reverseItem">
              <div className="gym-item-subarea">
                <p><span>自重の間</span><br/>トレーニングが苦手な方でもできる自重トレーニングをメインに行います</p>
              </div>
              <img src={GYM2} class="gym-interior" alt="img..."/>
            </div>
          </div>
          <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.7811014154304!2d134.70374441510228!3d34.786285586137645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3554de23cd8bf297%3A0x3c8666bddd64a978!2z77yI5qCq77yJ5aSp44Oe5bGL!5e0!3m2!1sja!2sjp!4v1628871532143!5m2!1sja!2sjp" allowfullscreen="" loading="lazy" className="map-frame"></iframe>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default StoreInfo;