import React from "react";
import "../App.css";
import { Header, Footer } from "../component/index";
import { Helmet } from "react-helmet";

const Course = () => {
  const title = "Result | ROCK GYM セミパーソナルジム | 姫路";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Header />
      <div className="container">
        <h2 className="page-title">コース</h2>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">12回コース</p>
            <p className="course-description">まず初めに1回60分の12回コースを受けて頂きます。<br/>このコースでは今の自分に最適な負荷でトレーニングをおこない、<br/>正しいフォームを身に付けていきます。</p>
            <p className="price">¥39,600<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">セミパーソナル4回券</p>
            <p className="course-description">12回コース修了後、月4回のセミパーソナル回数券です。</p>
            <p className="price">¥8,800<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">セミパーソナル8回券</p>
            <p className="course-description">12回コース修了後、月8回のセミパーソナル回数券です。<br/>10%off!!</p>
            <p className="price">¥15,400<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">パーソナル30</p>
            <p className="course-description mb-3">30分のマンツーマンのコースです。</p>
            <div className="course-30">
              <p className="course-subtitle">①ファンクショナル30</p>
              <p className="course-description">体力・筋力・パフォーマンス向上</p>
            </div>
            <div className="course-30">
              <p className="course-subtitle">②オールアウト30</p>
              <p className="course-description">1部位を徹底的に追い込む</p>
            </div>
            <div className="course-30">
              <p className="course-subtitle">③ストレッチ30</p>
              <p className="course-description">パーソナルストレッチ</p>
            </div>
            <p className="price">¥3,300<span>（税込）</span></p>
          </div>
        </div>
        <div className="course-item">
          <div className="course-item-sub">
            <p className="course-title">10回券</p>
            <p className="course-description">お好きなパーソナル30のコースを<br/>自由に組み合わせれます。<br/>10%off!!</p>
            <p className="price">¥29,700<span>（税込）</span></p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Course;