import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const currentPath = window.location.pathname;

  return (
    <>
      <div className="footer">
        <div className="footer-item">
          {(currentPath === '/case' || currentPath === '/thanks') ||
          <div className="footerButton">
            <button type="button" class="btn footer-freetry-apply-button">
              <Link to="/case" className="footer-free-apply">無料体験を申し込む</Link>
            </button>
            <p>電話申込はこちら　<a href="tel:080-6914-6969" className="tel-link">080-6914-6969</a></p>
          </div>
          }
          {(currentPath === '/result' || currentPath === '/case' || currentPath === '/thanks') ||
          <div className="sns-icon-list">
            <a href="https://www.instagram.com/rockgym_workout/" target="_blank" rel="noreferrer" >
              <FontAwesomeIcon icon={faInstagram} className="sns-icon"/>
            </a>
          </div>
          }
        </div>
        <p className="copyright">Copyright @ 2021 ROCK GYM All Rights Reserved.</p>
      </div>
    </>
  );
}

export default Footer;