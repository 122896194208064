import React from "react";
import { useForm } from "react-hook-form";
import { Header, Footer } from "../component/index";
import { init, sendForm } from "emailjs-com";
import { Helmet } from "react-helmet";

const Contact = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailjsUserId = process.env.REACT_APP_EMAILJS_USER_ID;

  const title = "Contact | ROCK GYM セミパーソナルジム | 姫路";

  init(emailjsUserId);
  const onSubmit = (data) => {
    sendForm(emailjsServiceId, emailjsTemplateId, '#contact-form')
    .then((result) => {
        console.log(result.text);
        document.location.pathname = '/thanks';
    }, (error) => {
        console.log(error.text);
    });
  };
  
  const purpose_list = [{"value": "hidden", "option": "選択してください"}, {"value": "バルクアップ", "option": "バルクアップ"}, {"value": "ダイエット", "option": "ダイエット"}, {"value": "健康維持", "option": "健康維持"}, {"value": "体力作り", "option": "体力作り"}, {"value": "パフォーマンス向上", "option": "パフォーマンス向上"}, {"value": "シェイプアップ", "option": "シェイプアップ"}, {"value": "ストレッチ", "option": "ストレッチ"}, {"value": "その他", "option": "その他"}];
  const day_of_the_week = [{"value": "hidden", "option": "選択してください"}, {"value": "月", "option": "月"}, {"value": "火", "option": "火"}, {"value": "水", "option": "水"}, {"value": "木", "option": "木"}, {"value": "金", "option": "金"}, {"value": "土", "option": "土"}];
  const start_time = [{"value": "hidden", "option": "選択してください"},{"value": "9:00", "option": "9:00"},{"value": "10:00", "option": "10:00"},{"value": "11:00", "option": "11:00"},{"value": "12:00", "option": "12:00"},{"value": "13:00", "option": "13:00"},{"value": "14:00", "option": "14:00"},{"value": "15:00", "option": "15:00"},{"value": "16:00", "option": "16:00"},{"value": "17:00", "option": "17:00"},{"value": "18:00", "option": "18:00"},{"value": "19:00", "option": "19:00"},{"value": "20:00", "option": "20:00"}];

  return(
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="container">
        <Header />
        <h2 className="page-title">無料体験申し込み</h2>
        <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
          <table className="contact-form">
            <tr>
              <th><label>氏名<span>【必須】</span></label></th>
              <td>
                <input name="firstName" {...register("firstName", { required: true })} placeholder="ろっく" className="input-text"/>
                {errors.firstName && "必須項目です。"}
                <input {...register("lastName", { required: true })} placeholder="じむ" className="input-text"/>
                {errors.lastName && "必須項目です。"}
              </td>
            </tr>
            <tr>
              <th><label>氏名（カナ）<span>【必須】</span></label></th>
              <td>
                <input name="firstNameKana" {...register("firstNameKana", { required: true })} placeholder="ロック" className="input-text"/>
                {errors.firstNameKana && "必須項目です。"}
                <input name="lastNameKana" {...register("lastNameKana", { required: true })} placeholder="ジム" className="input-text"/>
                {errors.lastNameKana && "必須項目です。"}
              </td>
            </tr>
            <tr>
              <th><label>性別<span>【必須】</span></label></th>
              <td className="sex-area">
                <div className="sex-box">
                  <input name="sex" value="男性" type="radio" {...register("sex", { required: true })}/>男性
                </div>
                <div className="sex-box">
                  <input  name="sex"value="女性" type="radio" {...register("sex", { required: true })}/>女性
                </div>
              </td>
            </tr>
            <tr>
              <th><label>年齢<span>【必須】</span></label></th>
              <td>
                <input name="age" maxlength="2" pattern="\d*" {...register("age", { required: true })}  placeholder="半角数字" className="input-text"/>
                {errors.age && "必須項目です。"}
              </td>
            </tr>
            <tr>
              <th><label>電話番号<span>【必須】</span></label></th>
              <td>
                <input name="tel" type="tel" maxlength="11" pattern="\d*" {...register("tel", { required: true })} placeholder="半角数字" className="input-text wideInput"/>
                {errors.email && "必須項目です。"}
              </td>
            </tr>
            <tr>
              <th><label>メールアドレス<span>【必須】</span></label></th>
              <td>
                <input name="email" type="email" {...register("email", { required: true })} placeholder="rockgym@xxx.xxx" className="input-text wideInput"/>
                {errors.email && "必須項目です。"}
              </td>
            </tr>
            <tr>
              <th><label>目的<span>【必須】</span></label></th>
              <td>
                <select name="purpose" {...register("purpose", { required: true })} className="input-text wideInput">
                  {purpose_list.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
              </td>
            </tr>
            <p className="hope-time">希望日時</p>
            <tr>
              <th><label>第一希望<span>【必須】</span></label></th>
              <td>
                <select name="firstD" {...register("dayOfTheWeek1", { required: true })} className="input-select">
                  {day_of_the_week.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
                <select name="firstT" {...register("startTime1", { required: true })} className="input-select">
                  {start_time.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th><label>第二希望<span>【必須】</span></label></th>
              <td>
                <select name="secondT" {...register("dayOfTheWeek2", { required: true })} className="input-select">
                  {day_of_the_week.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
                <select name="secondT" {...register("startTime2", { required: true })} className="input-select">
                  {start_time.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th><label>第三希望<span>【必須】</span></label></th>
              <td>
                <select name="thirdT" {...register("dayOfTheWeek3", { required: true })} className="input-select">
                  {day_of_the_week.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
                <select name="thirdT" {...register("startTime3", { required: true })} className="input-select">
                  {start_time.map(item => {
                    if (item.value === "hidden") {
                      return <option hidden>{item.option}</option>
                    } else {
                      return <option value={item.value}>{item.option}</option>
                    }
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th><label>その他</label></th>
              <td>
                <textarea name="others" {...register("others")} placeholder="何かございましたらご記入ください。"  className="input-textarea"/>
              </td>
            </tr>
            <tr>
              <th><label>申込確認方法<span>【必須】</span></label></th>
              <td className="sex-area">
                <div className="sex-box">
                  <input name="confirm" value="電話" type="radio" {...register("confirm", { required: true })}/>電話
                </div>
                <div className="sex-box">
                  <input  name="confirm"value="メール" type="radio" {...register("confirm", { required: true })}/>メール
                </div>
              </td>
            </tr>
            <p>※選択された方法で改めてご連絡いたします。</p>
            {/* <tr>
              <th></th>
              <td>
                <input type="submit" value="送信する" className="submitButton"/>
              </td>
            </tr> */}
          </table>
          <input type="submit" value="送信する" className="submit-button"/>
          <p className="warning-for-submit">※送信できない場合、お手数ですが電話（080-6914-6969）にて問い合わせをよろしくお願いいたします。</p>
        </form>
        <Footer />
      </div>
    </>
  );
};

export default Contact;