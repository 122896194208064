import React from 'react';
import { Header, TextField, Select, FormControlLabelPlacement, OutlinedButton, Footer } from '../component/index';
import { makeStyles } from '@material-ui/core/styles';
import '../App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
}));

const CasePage = () => {
  const classes = useStyles();
  const purpose_list = ["バルクアップ", "ダイエット", "健康維持", "体力作り", "パフォーマンス向上", "シェイプアップ", "ストレッチ", "その他"];
  const day_of_the_week = ["日", "月", "火", "水", "木", "金", "土"];
  const start_time = ["9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", ];

  return (
    <div className="main">
      <div className="case-main">
        <Header />
        <h2>無料体験申し込み</h2>
        <div className="inputField">
          <p className="inputItem">氏名</p>
          <div className="inputField">
            <TextField
              name="姓"
              variant="outlined"
              multiline="false"
              rows="1"
              className={classes.root}
              color="default"
            />
            <TextField
              name="名"
              variant="outlined"
              multiline="false"
              rows="1"
              className={classes.root}
            />
          </div>
        </div>
        <div className="inputField">
          <p className="inputItem">カナ</p>
          <div className="inputField">
            <TextField
              name="セイ"
              variant="outlined"
              multiline="false"
              rows="1"
            />
            <TextField
              name="メイ"
              variant="outlined"
              multiline="false"
              rows="1"
            />
          </div>
        </div>
        <div className="inputField">
          <p className="inputItem">性別</p>
          <FormControlLabelPlacement />
        </div>
        <div className="inputField">
          <p className="inputItem">年齢</p>
          <TextField
            name="年齢"
            variant="outlined"
            multiline="false"
            rows="1"
          />
        </div>
        <div className="inputField">
          <p className="inputItem">メールアドレス</p>
          <TextField
            name="test@rockgym.com"
            variant="outlined"
            multiline="false"
            rows="1"
          />
        </div>
        <div className="inputField">
          <p className="inputItem">目的</p>
          <Select
            label = "目的"
            purpose = {purpose_list}
          />
        </div>
        <div className="inputField">
          <h3 className="inputItem">希望日時</h3>
          <div>
            <div className="inputField">
              <p className="inputItem">第一希望</p>
              <Select
                label = "曜日"
                purpose = {day_of_the_week}
              />
              <Select
                label = "開始時間"
                purpose = {start_time}
              />
            </div>
            <div className="inputField">
              <p className="inputItem">第二希望</p>
              <Select
                label = "曜日"
                purpose = {day_of_the_week}
              />
              <Select
                label = "開始時間"
                purpose = {start_time}
              />
            </div>
            <div className="inputField">
              <p className="inputItem">第三希望</p>
              <Select
                label = "曜日"
                purpose = {day_of_the_week}
              />
              <Select
                label = "開始時間"
                purpose = {start_time}
              />
            </div>
          </div>
        </div>
        <p>※ご希望に添えない場合もございますので、ご了承ください。</p>
        <div className="inputField">
          <p className="inputItem">その他</p>
          <TextField
            name="何かあればご記入ください。"
            variant="outlined"
            multiline="true"
            rows="5"
          />
        </div>
        <OutlinedButton />
        <Footer />
      </div>
    </div>
  );
}

export default CasePage;