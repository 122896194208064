import React from "react";
import "../App.css";
import { Header, Footer } from "../component/index";
import Result1 from "../images/result/result_1.jpg";
import Result2 from "../images/result/result_2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";

const Result = () => {
  const title = "Result | ROCK GYM セミパーソナルジム | 姫路";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="container">
        <Header />
        <h2 className="page-title">結果紹介</h2>
        <div>
          <p className="result-title">【結果紹介①】20代男性</p>
          <p className="result-info"> 体重：100.7kg → 79.6kg(-21.1kg)<br/>期間：6ヶ月</p>
          <div className="result-box">
            <img src={Result1} alt="結果紹介1" className="result-image"/>
            <p className="result-before-after">Before</p>
            <p className="result-before-after">After</p>
          </div>
        </div>
        <div>
          <p className="result-title">【結果紹介②】50代女性</p>
          <p className="result-info">体重：60.8kg → 52.8kg(-8.0kg)<br/>期間：3ヶ月</p>
          <div className="result-box">
            <img src={Result2} alt="結果紹介2" className="result-image"/>
            <p className="result-before-after">Before</p>
            <p className="result-before-after">After</p>
          </div>
        </div>
        <div className="more-sns-icon">
          <a href="https://www.instagram.com/rockgym_workout/" target="_blank" rel="noreferrer" >
            もっと結果紹介を見たい方はこちら　<FontAwesomeIcon icon={faInstagram} className="sns-icon"/>
          </a>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Result;