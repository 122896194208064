import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home, Contact, Course, StoreInfo, Result, Prevention, Thanks } from "./page/index";
import ScrollToTop from "./component/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/case" component={Contact} />
        <Route path="/course" component={Course} />
        <Route path="/storeinfo" component={StoreInfo} />
        <Route path="/result" component={Result} />
        <Route path="/prevention" component={Prevention} />
        <Route path="/thanks" component={Thanks} />
      </Switch>
    </Router>
  );
}

export default App;
