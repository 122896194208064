import React from "react";
import "../App.css";
import { Header, Footer } from "../component/index";
import { Helmet } from "react-helmet";

const Prevention = () => {
  const title = "Prevention | ROCK GYM セミパーソナルジム | 姫路";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="container">
        <Header />
        <div className="prevention-area">
          <h2 className="page-title">ROCK GYMの感染予防対策</h2>
          <div className="prevention-box">
            <div className="prevention-item">
              <div className="half-box">
                <p className="prevention-title">マスク着用</p>
              </div>
              <div className="half-box">
                <p className="prevention-description">
                  入館時からマスクの着用を必須としております。マスク未着用時の会話は原則禁止としております。
                </p>
              </div>
            </div>
            <div className="prevention-item reverseItem">
              <div className="half-box">
                <p className="prevention-title">消毒液設置</p>
              </div>
              <div className="half-box">
                <p className="prevention-description">
                  スタッフは随時手洗い、アルコール消毒を徹底いたします。また、会員様が触れる箇所のアルコール消毒を随時行います。
                </p>
              </div>
            </div>
            <div className="prevention-item">
              <div className="half-box">
                <p className="prevention-title">検温実施</p>
              </div>
              <div className="half-box">
                <p className="prevention-description">
                  スタッフの出勤前の体温測定の実施をしております。万一37.5℃を超えた場合、休館させていただく場合がございます。その際はご了承ください。
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Prevention;