import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SimpleSelect = (props) => {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  
  return (
  <FormControl variant="outlined" className={classes.formControl}>
  <InputLabel id="demo-simple-select-outlined-label">{props.label}</InputLabel>
  <Select
    labelId="demo-simple-select-outlined-label"
    id="demo-simple-select-outlined"
    value={age}
    onChange={handleChange}
    label={props.label}
  >
    <MenuItem value="">
      <em>None</em>
    </MenuItem>
    {/* <MenuItem value={1}>{props.purpose}</MenuItem> */}
    {props.purpose.map((p) => {
      return <MenuItem value={p}>{p}</MenuItem>
    })}
  </Select>
  </FormControl>
  );
}

export default SimpleSelect;